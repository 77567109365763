<template>
  <div class="max-auto bg-overlay-gray hidden w-full bg-white lg:block">
    <ul class="max-width flex items-center justify-end px-3">
      <li>
        <a
          href="https://business.hungryhub.com/"
          class="flex items-center px-2 text-sm text-black lg:px-4"
        >
          {{ t("forBusiness") }}
        </a>
      </li>
      <li>
        <nuxt-link
          to="#"
          class="flex items-center px-2 text-sm text-black lg:px-4"
        >
          {{ t("giftCard") }}
        </nuxt-link>
      </li>
      <li>
        <a
          href="https://blog.hungryhub.com/"
          class="flex items-center px-2 text-sm text-black lg:px-4"
        >
          {{ t("blog") }}
        </a>
      </li>
      <li>
        <div class="relative">
          <button
            type="button"
            class="relative flex cursor-pointer items-center border-none px-2 text-sm lg:px-4"
            @click="toggleDropdown"
          >
            <div class="mr-2 flex items-center justify-center text-black">
              <div class="mr-2 text-black">
                {{ t("downloadApp") }}
              </div>
              <iconDownArrow
                class="transition-all"
                :class="dropdownDownloadIsShow ? 'rotate-180' : ''"
              />
            </div>
            <div
              v-if="dropdownDownloadIsShow"
              class="absolute left-0 top-[24px] z-50 shadow-md"
            >
              <div class="w-[136px] rounded bg-white px-2 py-2 text-xs">
                <a
                  href="https://play.google.com/store/apps/details?id=com.hb.hungryhub"
                  class="mb-2"
                >
                  <HhImage
                    :src="iconGooglePlay"
                    :sources="sourceImageGooglePlay"
                    :use-mutator="false"
                    alt="google logo"
                    class="w-full"
                    :width="100"
                  />
                </a>
                <a
                  href="https://apps.apple.com/th/app/hungry-hub-dining-offer-app/id879303325"
                  class="mb-2"
                >
                  <HhImage
                    :src="iconAppStore"
                    :sources="sourceImageAppStore"
                    :use-mutator="false"
                    alt="app store"
                    class="mt-2 w-full"
                    :width="100"
                  />
                </a>
              </div>
            </div>
          </button>
        </div>
      </li>
      <li>
        <a
          href="https://hungryhub.zendesk.com/hc/en-us"
          class="flex items-center px-2 text-sm text-black lg:px-4"
        >
          {{ t("help") }}
        </a>
      </li>
      <li>
        <SelectLanguage />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import SelectLanguage from "./SelectLanguage.vue";
import { ref } from "#imports";
import iconDownArrow from "~icons/hh-icons/icon-down-arrow";
import HhImage, { type SrcSet } from "~/components/HhImage.vue";
import iconGooglePlay from "~/assets/image/icon-google-play.jpg";
import iconGooglePlay2x from "~/assets/image/icon-google-play@2x.jpg";
import iconAppStore from "~/assets/image/icon-app-store.jpg";
import iconAppStore2x from "~/assets/image/icon-app-store@2x.jpg";
const { t } = useI18n({
  useScope: "global",
});

const dropdownDownloadIsShow = ref(false);

const sourceImageGooglePlay: SrcSet[] = [
  {
    source: iconGooglePlay,
    useMutator: false,
  },
  {
    source: `${iconGooglePlay2x}`,
    useMutator: false,
    pixelDensity: "2x",
  },
];

const sourceImageAppStore: SrcSet[] = [
  {
    source: iconAppStore,
    useMutator: false,
  },
  {
    source: `${iconAppStore2x}`,
    useMutator: false,
    pixelDensity: "2x",
  },
];

function toggleDropdown() {
  dropdownDownloadIsShow.value = !dropdownDownloadIsShow.value;
}
</script>

<script lang="ts">
export default {
  name: "TopBar",
  components: { SelectLanguage },
};
</script>

<style lang="scss" scoped>
.bg-overlay-gray {
  background: linear-gradient(90deg, #ffffff 54.72%, #ededed 64.13%);
}
</style>
