<template>
  <div class="relative">
    <button
      class="relative flex h-[30px] w-[30px] cursor-pointer items-center border-none px-2 text-sm lg:px-4"
      @click="toggleDropdown"
    >
      <div class="mr-2 flex items-center justify-center uppercase text-black">
        <LanguagePicker :lang="configStore.lang">
          <template #default="{ selectedLanguage }">
            <div class="flex items-center justify-center">
              <component :is="selectedLanguage.icon" class="w-4 rounded-full" />
              {{ selectedLanguage.lang }}
            </div>
          </template>
        </LanguagePicker>
        <iconDownArrow
          class="text-black transition-all"
          :class="dropdownLangIsShow ? 'rotate-180' : ''"
        />
      </div>
    </button>
    <div v-if="dropdownLangIsShow" class="absolute left-0 z-50 shadow-md">
      <div class="w-[90px] rounded bg-white px-2 text-xs">
        <LanguagePicker :lang="configStore.lang">
          <template #default="{ availableLanguage }">
            <div
              v-for="language in availableLanguage"
              :key="`language-option-${language.lang}`"
              class="flex cursor-pointer items-center justify-center pb-2 pt-3 uppercase"
              @click="configStore.lang = language.lang"
            >
              <component :is="language.icon" class="mr-2" />
              <span class="text-black">{{ language.lang }}</span>
            </div>
          </template>
        </LanguagePicker>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "#imports";
import useConfigStore from "~/stores/config";
import LanguagePicker from "~/components/LanguagePicker.vue";
import iconDownArrow from "~icons/hh-icons/icon-down-arrow";

const configStore = useConfigStore();

const dropdownLangIsShow = ref(false);

const toggleDropdown = () => {
  dropdownLangIsShow.value = !dropdownLangIsShow.value;
};
</script>
<script lang="ts"></script>
